import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_ID, ApplicationConfig, LOCALE_ID, importProvidersFrom, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRouteProviders } from './app-routing.module';
import { Meta, provideClientHydration } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpUrlInterceptor } from './http-url.interceptor';
import { CleverInterceptor } from './services/http.service';
import { UrlSerializer } from '@angular/router';
import { CustomeUrlSerializer } from './CustomeUrlSerializer';
import { LanguageService } from './services/translate/language.service';
import { WebLanguageService } from './services/translate/web-language.service';
import { I18nService } from './services/i18n.service';

const GRI_DATE_FORMATS: MatDateFormats = {
    ...MAT_NATIVE_DATE_FORMATS,
    display: {
        ...MAT_NATIVE_DATE_FORMATS.display,
        dateInput: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        } as Intl.DateTimeFormatOptions,
    }
};

export class DynamicLocaleId extends String {
    constructor(protected service: I18nService) {
        super('');
    }

    override toString() {
        return this.service.langLong;
    }
}
export const appConfig: ApplicationConfig = {
    providers: [
        ...AppRouteProviders,
        provideExperimentalZonelessChangeDetection(),
        provideAnimations(),
        provideHttpClient(withFetch()),
        provideClientHydration(),
        provideHttpClient(withInterceptors([HttpUrlInterceptor])),
        importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        })),
        { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
        Meta,
        {
            provide: LOCALE_ID,
            useClass: DynamicLocaleId,
            deps: [I18nService]
        },
        { provide: APP_ID, useValue: 'cleverreisen' },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0'
        },
        { provide: LanguageService, useClass: WebLanguageService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CleverInterceptor,
            multi: true
        },
        {
            provide: UrlSerializer,
            useClass: CustomeUrlSerializer,
        },
    ],
};
